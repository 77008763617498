.bg-dark {
  background: black;
}

.bg-light {
  background: white;
}

.text-white {
  color: white;
}
